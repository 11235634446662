html {
  width: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  scroll-snap-type: y mandatory;
  width: 100%;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  /* align-items: center; */
  overflow-y: scroll;
  /* margin-bottom: 200px; */
  padding-bottom: 200px;
}

.container section {
  /* width: 100%; */
  /* min-height: 100vh; */
  /* scroll-snap-align: start; */
  /* border-bottom: 1px solid #cccccc; */
  display: flex;
  flex-direction: column;
}

.technicalHeader {
  text-align: center;
}

.sectionHeader {
  font-family: 'Montserrat', sans-serif;
}

.footer {
  margin-top: 100px;
  padding-bottom: 100px;
  color: #AAAAAA;
  font-family: Rubik, sans-serif;
}

a {
  color: '#cccccc'
}